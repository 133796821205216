angular.module('app')
    .directive("asciiOnly",
        ['Notification',
            function (Notification) {
                return {
                    require: 'ngModel',
                    link: function ($scope, element, attr, ngModelCtrl) {
                        function fromUserInput(text) {
                            var parentForm = element.inheritedData('$formController');
                            var ascii = /^[ a-zA-Zà-żžá-źÀ-ÿ0-9,.':;"?{}()=%$#@!*&_\n\-]+$/;
                            if (text !== "" && !ascii.test(text)) {
                                Notification.error({message: 'Field contains invalid characters', delay: 3000});
                                element.focus();
                                parentForm.$setValidity('latinOnly', false, element);
                            }
                            else {
                                parentForm.$setValidity('latinOnly', true, element);
                            }

                            return text;
                        }

                        ngModelCtrl.$parsers.push(fromUserInput);
                    }
                };
            }]);
